* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
    margin: 0 0 5px 0;
}

.close {
    background: none;
    color: white;
    border: 0;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-auto-rows: 200px;
}

.item,
.span-item {
    overflow: hidden;
    display: grid;
}

.item__overlay,
.spanOverlay {
    background: rgb(31 41 55);
    grid-column: 1/-1;
    grid-row: 1/-1;
    opacity: 0.5;
    position: relative;
    display: grid;
    justify-items: right;
    align-items: flex-start;
    padding-top: 1rem;
    transition: 0.5s;
    transform: translateY(100%);
}

.item__overlay p {
    background: none;
    color: white;
    text-transform: uppercase;
    background-attachment: rgba(0, 0, 0, 0.7);
    padding: 5px;
}

.spanOverlay>p {
    background: none;
    color: white;
    text-transform: uppercase;
    background-attachment: rgba(0, 0, 0, 0.7);
    padding: 5px;
    overflow: hidden;
}

.item img,
.span-item img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.item.h2,
.span-item.h2 {
    grid-row: span 2;
}

.item.h3,
.span-item.h3 {
    grid-row: span 3;
}

.item.h4,
.span-item.h4 {
    grid-row: span 4;
}

.item.v2,
.span-item.v2 {
    grid-column: span 2;
}

.item.v3,
.span-item.v3 {
    grid-column: span 3;
}

.item.v4,
.span-item.v4 {
    grid-column: span 4;
}

.spanImg {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-auto-rows: 200px;
}

.popup {
    display: none;
    transform: scale(0);
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.popup.h2 {
    animation-name: scale-up;
}

.popup.v2 {
    animation-name: scale-right;
}

.item:hover {
    transform: scale(1);
    transition-duration: 0.8s;

    .item__overlay {
        transform: translateY(80%);
    }

    .height-1 {
        transform: translateY(75%);
    }

    .height-2 {
        transform: translateY(85%);
    }

    .height-3 {
        transform: translateY(90%);
    }
}

.span-item:hover {
    .item__overlay {
        transform: translateY(80%);
    }

    .height-1 {
        transform: translateY(75%);
    }

    .height-2 {
        transform: translateY(85%);
    }

    .height-3 {
        transform: translateY(90%);
    }

    .spanImg {
        overflow: hidden;
        position: absolute;
        visibility: visible;
        z-index: 2;

        .spanOverlay {
            position: absolute;
            transition-delay: 0.7s;
            transform: translateY(90%);
        }

        .popup {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
        }

        .popup.h2 {
            grid-row: span 3;
        }

        .popup.v2 {
            grid-column: span 3;
        }
    }
}

@keyframes scale-up {
    from {
        transform: translateY(-10%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes scale-right {
    from {
        transform: translateX(-10%);
    }

    to {
        transform: translateX(0);
    }
}
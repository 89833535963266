@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Peta:wght@100;200;300;400;500;600;700;800;900&display=swap');

.text-roboto {
  font-family: 'Roboto', 'Josefin Sans', sans-serif;
}

.text-lexend-peta {
  font-family: 'Lexend Peta', 'Roboto', 'Josefin Sans', sans-serif;
}
